const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Upload Settlement',
      //   buttonRef: 'uploadSettlementBtn',
      //   variant: 'info',
      // },
    ],
    txnTypeFilter: [
      {
        buttonLabel: 'All',
        buttonRef: 'allTxnFilter',
        active: true,
      },
      {
        buttonLabel: 'Normal',
        buttonRef: 'normalTxnFilter',
        active: false,
      },
      {
        buttonLabel: 'Prefund',
        buttonRef: 'prefundTxnFilter',
        active: false,
      },
      {
        buttonLabel: 'Transaction',
        buttonRef: 'transactionTxnFilter',
        active: false,
      },
      {
        buttonLabel: 'Bill Payment',
        buttonRef: 'billPaymentTxnFilter',
        active: false,
      },
    ],
    buttons: {

      refresh: true,
    },
    datepicker: true,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'merchant_id', text: 'Merchant ID' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Commission',
        view: 'View Commission',
        remove: 'Remove Commission',
        create: 'Create New Commission',
      },
      form: [],
    }
  },
  tableData() {
    return {
      tableAction: [

        {
          enable: true, param: 'id', btnLabel: 'View', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', actionParam: 'viewCostCentreDetail', disableCondition: 'transaction_type:x',
        },
        // {
        //   enable: true, param: 'report_id', btnLabel: 'View', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'viewCommissionDetail', disableCondition: 'status:TEST',
        // },
        // {
        //   enable: false, param: 'report_id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', pageRoute: 'administration-commissions-datatable-details',
        // },
        // {
        //   enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        //   // pageRoute: 'administration-plans-form',
        // },
        // {
        //   enable: true, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        // },
      ],
      tableHeader: [
        // {
        //   "id": 10,
        //   "total_commission_amount": 0,
        //   "bank_account_number": "3432212312322",
        //   "merchant_id": "LP-42C9A515-MM",
        //   "response_data": null,
        //   "status": "NO_NEED_SETTLEMENT",
        //   "created_at": "2024-07-23T16:36:57",
        //   "release_date": "2024-07-23T16:36:57",
        //   "account_id": 77,
        //   "payout_transaction_no": null,
        //   "report_id": "58bddf5c-f6e3-473e-8e2b-91e08c165904",
        //   "record_status": 1,
        //   "updated_at": "2024-07-23T16:36:57"
        // }
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },

        {
          key: 'id', label: 'Id', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'report_id', label: 'Report Id', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'invoice_failed_count', label: '# Failed', sortable: true, visible: true, type: 'number',
        },
        {
          key: 'total_failed_transaction', label: 'Failed TXN', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'invoice_pending_count', label: '# Pending', sortable: true, visible: true, type: 'number',
        },
        {
          key: 'total_pending_transaction', label: 'Pending TXN', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'invoice_success_count', label: '# Success', sortable: true, visible: true, type: 'number',
        },
        {
          key: 'total_success_transaction', label: 'Success TXN', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'total_fee', label: 'Fee', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'total_switch_fee', label: 'PS Fee', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'id', label: 'Id', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'transaction_date', label: 'Transaction Date', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'settlement_report_date', label: 'Generated Date', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'transaction_type', label: 'TXN Type', sortable: true, visible: true, class: 'text-right', type: 'costCentreTxnType',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, class: 'text-right', type: 'date',
        },
        {
          key: 'status', label: 'status', sortable: true, visible: false, type: 'settlement_status',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, class: 'text-right', type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
